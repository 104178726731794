<template>
  <v-app class="">
    <v-app-bar class="nav-padding" app flat>
      <v-app-bar-nav-icon @click="state.isDrawerOpen = true"></v-app-bar-nav-icon>

      <router-link class="ml-2 mr-4 d-none d-md-block" to="/">
        <v-img max-height="100%" width="80px" :src="require('@/assets/logos/logo-nav.png')"
          alt="Hire Heroes Logo"></v-img>
      </router-link>

      <div class="ml-auto mr-4">
        <v-avatar size="35px">
          <img :src="profilePicture" alt="" loading="lazy" />
        </v-avatar>
        <small class="ml-2">{{ userData.first_name }} {{ userData.last_name }}</small>
      </div>

      <div>
        <v-btn @click="logout" small fab depressed>
          <v-icon class="" size="40px" small>{{ icons.mdiLogout }}</v-icon>
        </v-btn>
      </div>

    </v-app-bar>

    <v-navigation-drawer v-model="state.isDrawerOpen" fixed temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Admin Dashboard
          </v-list-item-title>
          <v-list-item-subtitle> HireHeroes </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item v-for="item in navMenuItems" :key="item.to" :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <div class="d-flex flex-row">
      <v-main>
        <slot></slot>
      </v-main>
    </div>

  </v-app>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { useUser } from "@/composables/user/user";
import socket from '../plugins/socket.js'
import {
  mdiPercent,
  mdiLogout,
  mdiViewDashboard,
  mdiMessage,
  mdiClipboardAccount,
  mdiAccount,
  mdiAccountCheck,
  mdiCommentQuestion,
  mdiAccountPlus,
  mdiFolderAccount,
  mdiCogOutline,
  mdiCurrencyUsd
} from "@mdi/js";

export default {
  name: "LayoutContent",
  setup() {
    const state = reactive({
      isDrawerOpen: false,
    });

    const { userData, profilePicture } = useUser();

    const navMenuItems = [
      {
        text: "Dashboard",
        to: "/dashboard",
        icon: mdiViewDashboard,
      },
      {
        text: "Accounts",
        to: "/accounts",
        icon: mdiAccount,
      },
      {
        text: "Account Archive",
        to: "/account-archive",
        icon: mdiFolderAccount,
      },
      {
        text: "Add Virtual Assistant",
        to: "/add-user",
        icon: mdiAccountPlus
      },
      {
        text: "Assign VAs",
        to: "/manage-assignment",
        icon: mdiClipboardAccount,
      },
      {
        text: "Conversations",
        to: "/conversations",
        icon: mdiMessage,
      },
      {
        text: "Support Messages",
        to: "/support",
        icon: mdiCommentQuestion
      },
      {
        text: "Manage Pricing",
        to: "/manage-pricing",
        icon: mdiCurrencyUsd,
      },
      {
        text: "Manage Discount",
        to: "/manage-discount",
        icon: mdiPercent,
      },
      {
        text: "Settings",
        to: "/settings",
        icon: mdiCogOutline
      }
    ];

    function logout() {
      socket.emit('leave-room','admin')
      socket.disconnect();
      localStorage.removeItem('shouldReconnect')
      localStorage.setItem("accessToken", null);
      localStorage.setItem("userData", null);
      this.$router.push({ name: "login" });
    }

    return {
      state,
      logout,
      profilePicture,
      navMenuItems,
      userData,
      icons: {
        mdiLogout,
        mdiViewDashboard,
        mdiMessage,
        mdiClipboardAccount,
        mdiAccount,
        mdiAccountCheck,
        mdiCommentQuestion,
        mdiAccountPlus,
        mdiFolderAccount
      },
    };
  },
};
</script>

<style>
@media screen and (min-width: 987px) {
  .nav-padding {
    padding: 0px 44px !important;
  }
}

.dashboard-wrapper {
  flex-grow: 1;
}

header {
  flex-basis: 0px;
}
</style>
