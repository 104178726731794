import {io} from 'socket.io-client'
const socket = io('https://socket.hireheroes.com',{
  autoConnect: false,            // Prevent auto-connect on initialization
  reconnection: true,             // Enable reconnection
  reconnectionAttempts: Infinity, // Try to reconnect indefinitely
  reconnectionDelay: 1000,        // Initial delay of 1 second for reconnections
  reconnectionDelayMax: 5000,     // Maximum delay of 5 seconds between reconnections
  timeout: 20000                  // Connection timeout of 20 seconds
});
console.log('process.env.SOCKET_URL',socket)

  socket.on('connect',()=>{
    console.log('Socket Connection established')
    socket.emit('join-room','admin')
  })

  // Error handling
  socket.on('error', (error) => {
    console.error('Connection error:', error.message);
  });

  export default socket