<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <h2 class="mb-4 cursive-font">Preview Accounts</h2>

    <v-alert dismissible>
      From this page, you can view the main HireHeroes website from the POV of
      one of the users.
    </v-alert>

    <table-with-filters ref="tableWithFilterRef" :options="tableOptions" :customColumns="customColumns" @refresh="onWalletUpdate" >
      <!-- User Profile -->
      <template v-slot:user-name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item)" alt="" loading="lazy" />
          </v-avatar>
          <span class="ml-2 text-capitalize">{{ item.first_name }} {{ item.last_name }}</span>
        </div>
      </template>

      <!-- User Profile -->
      <template v-slot:account_type="{ item }">
        <div>
          <span class="ml-2">{{
            item.account_type == "va" ? "Virtual Client" : "Client"
            }}</span>
        </div>
      </template>

      <template v-slot:auto-pay="{item}">
        <div v-if="item.wallet">
          <span :class="['auto-pay',item.wallet.auto_pay ? 'enable' : 'stop']">autopay</span>
        </div>
        <div v-else>
          <span :class="['auto-pay stop disable']">autopay</span>
        </div>
      </template>

      <!-- Wallet Balance -->
      <template v-slot:wallet="{ item }">
        <div class="" v-if="item.account_type == 'client'">
          <span class="ml-4">{{ convertMinutesToHHMM(item.id,item.wallet.amount)}}</span>
        </div>
        <div class="ml-4" v-else>00h:00m</div>
      </template>

      <!-- Preview,Manage Hours,Resend Verification,Archive Buttons -->

      <template v-slot:preview-btn="{ item }">
        <div class="d-flex justify-end">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon color="#808080">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- Preview Button -->
              <v-list-item @click="setPreviewTarget(item)" :disabled="!item.is_email_verified">
                <v-list-item-title>Preview</v-list-item-title>
              </v-list-item>

              <!-- Resend Verification Button -->
              <v-list-item @click="refreshWallet(item.id,item.wallet.id,item.ssm_id)" v-if="item.account_type == 'client'" :disabled="item.wallet.auto_pay" >
                <v-list-item-title>Refresh Wallet</v-list-item-title>
              </v-list-item>

              <!-- Manage Hours Button -->
              <v-list-item @click="openDialog(item)" :disabled="item.account_type == 'va' || !item.is_email_verified || item.wallet.auto_pay">
                <v-list-item-title>Manage Hours</v-list-item-title>
              </v-list-item>

              <!-- Resend Verification Button -->
              <v-list-item @click="resendVerificationEmail(item.id)" v-if="!item.is_email_verified">
                <v-list-item-title>Resend Verification Email</v-list-item-title>
              </v-list-item>

              <!-- Archive Button -->
              <v-list-item @click="deleteAccount(item.id)">
                <v-list-item-title class="text-custom-red">Archive</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </div>
      </template>

    </table-with-filters>

    <view-as v-if="isPreviewing"></view-as>

    <v-alert :type="isError ? 'error' : 'success'" class="popup-message" v-if="isVisible">
      {{ message }}
    </v-alert>

    <!-- Manage Hours Dialog Box -->
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">Client Wallet</v-card-title>
        <v-alert :type="isWalletError ? 'error' : 'success'" text dense v-if="isWalletVisible">{{ walletMessage
          }}</v-alert>
        <v-card-text>
          <v-small>You can <b>credit</b> or <b>debit</b> hours from client
            wallet.</v-small>
          <!-- class="wallet-balance" -->
          <div style="
              margin: 16px;
              display: flex;
              flex-direction: column;
              gap: 10px;
            ">
            <div>Email : {{ user.email }}</div>
            <div>
              Total Hours : {{ convertMinsToHrsMins(wallet.total_credit,'credit') }}
            </div>
            <div>
              Spend Hours : {{ convertMinsToHrsMins(wallet.total_spend,'spend') }}
            </div>
            <div>
              Available Hours : {{ convertMinsToHrsMins(wallet.amount,'amount') }}
            </div>
          </div>

          <!-- Select Transaction Type -->
          <div>
            <strong>What type of transaction you want to proceed ?</strong>
            <v-radio-group inline v-model="transaction_type">
              <v-radio label="Credit" value="1"></v-radio>
              <v-radio label="Debit" value="0"></v-radio>
            </v-radio-group>
          </div>

          <!-- Add Hours Section -->
          <div>
            <strong>How much Hours ?</strong>
            <v-text-field v-model="textBox" type="number" label="Hours" placeholder="Enter Hours" :min="0"
              hide-details="auto" class="mb-2">
            </v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="dialog = false" :disabled="isLoading">
            close
          </v-btn>
          <v-btn color="green darken-1" text @click="update()" :disabled="isLoading || textBox.value == 0" :loading="isLoading">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { resolveProfilePic } from "@/helpers";
import { useViewAs } from "@/composables/accounts/view-as.js";
import { ref,nextTick, onMounted,provide } from "@vue/composition-api";
import ViewAs from "@/components/accounts/ViewAs.vue";
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import axios from "@axios";
import realTimeWalletCache from "@/plugins/localCache";

export default {
  name: "Accounts",
  components: { TableWithFilters, ViewAs },
  setup() {
    const tableWithFilterRef = ref(null)
    const isVisible = ref(false);
    const isError = ref(false);
    const message = ref(null);
    const dialog = ref(false);
    const switch1 = ref(true);
    const textBox = ref("");
    const wallet = ref({});
    const user = ref({});
    const isWalletVisible = ref(false);
    const isWalletError = ref(false);
    const walletMessage = ref(null);
    const transaction_type = ref("1");
    const isLoading = ref(false);
    // Create a reference to hold the child methods
    const childMethods = ref(null);

    const tableHeaders = [
      { text: "Name", value: "username", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Account Type", value: "account_type", sortable: false },
      { text: "Walet Balance", value: "wallet", sortable: false },
      { text: "", value: "autoPay", sortable: false },
      { text: "", value: "previewBtn", sortable: true },
    ];

    const openDialog = async (item) => {
      dialog.value = true;
      user.value = item;
      console.log("openDialog Item", item, user.value);
      const { data: walletDetail } = await axios.get(
        `/wallet/${user.value.id}`
      );
      if (walletDetail) {
        wallet.value = walletDetail.data;
        // convertMinsToHrsMins(walletDetail.minutes)
      }
    };

    const customColumns = [
      { slotName: "user-name", itemValue: "username" },
      { slotName: "preview-btn", itemValue: "previewBtn" },
      { slotName: "wallet", itemValue: "wallet"},
      { slotName: "auto-pay", itemValue: "autoPay"}
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
      {
        label: "User Type",
        value: "account_type",
        component: "v-select",
        items: ["client", "va"],
      },
    ];

    const { setPreviewTarget, isPreviewing } = useViewAs();

    const setAlertData = (msg = "Hello", err, show, duration) => {
      message.value = msg;
      isError.value = err;
      isVisible.value = show;

      setTimeout(() => {
        message.value = null;
        isError.value = false;
        isVisible.value = false;
      }, duration);
    };

    const deleteAccount = async (id) => {
      try {
        const data = await axios.post(`/user/${id}/disable`, {});
        if (data) {
          setAlertData("Account Disabled Successfully", false, true, 2500);
        }
      } catch (err) {
        console.log(err, "Frontend");
        setAlertData("Account Not Disabled", true, true, 2500);
        throw err;
      }
    };

    const resendVerificationEmail = async (id) => {
      try {
        const data = await axios.post(`/auth/resend-verification-email`, {
          uid: id
        });
        if (data) {
          setAlertData("Verification email sent Successfully", false, true, 2500);
        }
      } catch (error) {
        console.log(error.response.data.message, "Frontend");
        setAlertData(error.response.data.message, true, true, 2500);
        // throw error;
      }
    };

    // const convertMinsToHrsMins = (mins) => {
    //   // alert(mins)
    //   if(mins == 0) return `${String(0).padStart(2, '0')}:${String(0).padStart(2, '0')}`
    //   let h = Math.floor(mins / 60);
    //   let m = mins % 60;
    //   h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    //   m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    //   return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
    // }

    const convertMinsToHrsMins = (tminutes,purpose) => {
      let totalMinutes
      if(purpose == 'amount'){
        if(realTimeWalletCache.has(user.value.id)){
          totalMinutes = realTimeWalletCache.get(user.value.id)
        }
        else{
          totalMinutes = tminutes
        }
      }
      else if(purpose == 'balance'){
        if(realTimeWalletCache.has(user.value.id)){
          totalMinutes = tminutes - Math.abs(realTimeWalletCache.get(user.value.id))
        }
        else{
          totalMinutes = tminutes
        }
      }
      else{
        totalMinutes = tminutes
      }

      // Calculate hours and remaining minutes
      const hours = Math.floor(Math.abs(totalMinutes) / 60);
      const minutes = Math.abs(totalMinutes) % 60;

      // Format hours and minutes with leading zeros
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");

      // Determine if the original minutes were negative
      const sign = totalMinutes < 0 ? "-" : "";

      // Return the formatted time
      return `${sign}${formattedHours}:${formattedMinutes}`;
    };

    const displayWalletMessage = (err, msg = "", isVisible) => {
      isWalletError.value = err;
      walletMessage.value = msg;
      isWalletVisible.value = isVisible;
    };

    // update client wallet
    const update = async () => {
      try {
        isLoading.value = true;
        if (textBox.value == 0) {
          displayWalletMessage(
            true,
            `${textBox.value} Hours Not a Valid VAlue.`,
            true
          );
          setTimeout(() => {
            displayWalletMessage(false, "", false);
          }, 1000);
          return;
        }
        const result = await axios.patch("/wallet/manage-hours", {
          user_id: user.value.id,
          user_email:user.value.email,
          wallet_id: wallet.value.id,
          hours: Math.abs(textBox.value),
          action_type: transaction_type.value,
        });
        wallet.value = result.data.data;
        console.log("updated Wallet", result.data.data);
        displayWalletMessage(false, `${textBox.value} Hours Added`, true);
        setTimeout(() => {
          displayWalletMessage(false, "", false);
          textBox.value = "";
        }, 1000);
      } catch (error) {
        console.log("Error", error);
        displayWalletMessage(true, `${textBox.value} Hours Not Added`, true);
        setTimeout(() => {
          displayWalletMessage(false, "", false);
        }, 1000);
      } finally {
        isLoading.value = false;
      }
    };

    const convertMinutesToHHMM = (user_id,minutes) => {


      if(realTimeWalletCache.has(user_id)){
        minutes = realTimeWalletCache.get(user_id)
      }

    // Special case: if minutes is 0, return 00h:00m
    if (minutes === 0) {
        return "00h:00m";
    }

    const isNegative = minutes < 0;
    minutes = Math.abs(minutes);  // Convert to positive for calculations

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Ensure two-digit formatting for minutes
    const formattedMinutes = mins < 10 ? `0${mins}` : mins;

    // If minutes are zero, show only hours
    if (mins === 0) {
        return `${isNegative ? '-' : ''}${hours}h`;
    }

    return `${isNegative ? '-' : ''}${hours}h:${formattedMinutes}m`;  // Add negative sign only once
    };

    // const refreshWallet = async(walletID,ssmID) => {
    //   try {
    //     const { data: wallet } = await axios.get(`/wallet/refresh-wallet/${walletID}/${ssmID}`);
    //     console.log("Wallet", wallet);
    //     return wallet;
    //   } catch (error) {
    //     console.log("Error", error);
    //     setAlertData("Failed to refresh users", true, true, 2500);
    //   }
    // }

    const refreshWallet = async (userID,walletID,ssmID) =>{
      console.log(userID,walletID,ssmID,childMethods.value)
      
      // Call the provided method to modify the array in the child component
      if (childMethods && childMethods.value.refreshWallet) {
        childMethods.value.refreshWallet(userID,walletID,ssmID);
      }
    }

    const onWalletUpdate = (isErr,message)=>{
      setAlertData(message, isErr, true, 2500);
    }



    // Provide the methods and the reference for child methods
    provide('childMethods', childMethods);

    return {
      tableHeaders,
      deleteAccount,
      openDialog,
      tableOptions: {
        endpoint: "/user",
        headers: tableHeaders,
        filtersTitle: "Filter Users",
        filterFields,
      },
      textBox,
      convertMinsToHrsMins,
      customColumns,
      switch1,
      isPreviewing,
      setPreviewTarget,
      dialog,
      resolveProfilePic,
      isVisible,
      isError,
      message,
      wallet,
      update,
      isWalletVisible,
      isWalletError,
      walletMessage,
      user,
      transaction_type,
      isLoading,
      resendVerificationEmail,
      convertMinutesToHHMM,
      refreshWallet,
      onWalletUpdate
    };
  },
};
</script>

<style scoped>
.wallet-balance {
  font-size: 1.1rem;
  padding: 1rem 0 1rem 0;
}

.box {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.popup-message {
  position: fixed;
  right: 10px;
  top: 75px;
  width: 300px !important;
  z-index: 999;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 500;
}

.text-custom-red {
  color: #ed0717;
  /* Your desired red color */
}

.wallet-balance{
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
}

.auto-pay::before {
    content: '';
    display: inline-block;
    width: 8px;        /* Size of the dot */
    height: 8px;       /* Size of the dot */
    border-radius: 50%; /* Make it a circle */
    margin-right: 5px;  /* Space between the dot and the text */
    vertical-align: middle; /* Align the dot with the text */
}

.stop {
    color: red;  /* Red text for stop */
}

.stop::before {
    background-color: red;  /* Red dot for stop */
}

.enable {
    color: green; /* Green text for enable */
}

.enable::before {
    background-color: green; /* Green dot for enable */
}

.disable{
  visibility: hidden
}
</style>
